const htmlElement = document.querySelector("html");
const bodyElement = document.querySelector("body");

const buttonToggleWcag = document.querySelectorAll(".js-toggle-wcag");

const buttonColorPicker = document.querySelectorAll(".js-wcag-color-picker");
const buttonFontSizePicker = document.querySelectorAll(
    ".js-wcag-font-size-picker"
);

const font_sizes = ["small", "medium", "large"];
const color_themes = ["white", "black", "blue", "beige", "brown"];

const toggleWcag = () => {
    if (htmlElement.classList.contains("wcag")) {
        htmlElement.classList.remove("wcag");
        clearAllFonts();
        clearAllThemes();
        clearLocalStorage();
        moveHeader(false);
        return;
    }

    htmlElement.classList.add("wcag");
    selectFontSize();
    selectTheme();
    moveHeader(true);
    setCookie("wcag", true, 3);
};

const clearAllThemes = () => {
    buttonColorPicker.forEach((btn) => btn.classList.remove("active"));

    color_themes.forEach((theme) =>
        bodyElement.classList.remove(`wcag__theme--${theme}`)
    );
};

const clearAllFonts = () => {
    buttonFontSizePicker.forEach((btn) => btn.classList.remove("active"));

    font_sizes.forEach((size) =>
        htmlElement.classList.remove(`wcag__text-size--${size}`)
    );
};

const selectTheme = (color = "white") => {
    clearAllThemes();

    bodyElement.classList.add(`wcag__theme--${color}`);
    setCookie("wcag_theme", color, 3);
};

const selectFontSize = (size = "normal") => {
    const current_font_size = getCookie("wcag_font_size");
    if (size === "less") {
        switch (current_font_size) {
            case "normal":
                break;
            case "medium":
                clearAllFonts();
                htmlElement.classList.add(`wcag__text-size--normal`);
                setCookie("wcag_font_size", "normal", 3);
                break;
            case "large":
                clearAllFonts();
                htmlElement.classList.add(`wcag__text-size--medium`);
                setCookie("wcag_font_size", "medium", 3);
                break;
            default:
                clearAllFonts();
                htmlElement.classList.add(`wcag__text-size--normal`);
                setCookie("wcag_font_size", "normal", 3);
                break;
        }
    }

    if (size === "large") {
        switch (current_font_size) {
            case "normal":
                clearAllFonts();
                htmlElement.classList.add(`wcag__text-size--medium`);
                setCookie("wcag_font_size", "medium", 3);
                break;
            case "medium":
                clearAllFonts();
                htmlElement.classList.add(`wcag__text-size--large`);
                setCookie("wcag_font_size", "large  ", 3);
                break;
            case "large":
                break;
            default:
                clearAllFonts();
                htmlElement.classList.add(`wcag__text-size--medium`);
                setCookie("wcag_font_size", "medium", 3);
                break;
        }
    }
};

const checkLocalStorage = () => {
    bodyElement.style = "";

    const wcag = getCookie("wcag");

    if (!wcag) {
        moveHeader(false);
        return;
    }

    htmlElement.classList.add("wcag");
    const theme = getCookie("wcag_theme");
    const fontsize = getCookie("wcag_font_size");

    const colorBtn = document.querySelector(`button[data-color="${theme}"]`);
    const fontBtn = document.querySelector(`button[data-size="${fontsize}"]`);

    selectTheme(theme);
    selectFontSize(fontsize);
    moveHeader(true);

    if (colorBtn) {
        colorBtn.classList.add("active");
    }
    if (fontBtn) {
        fontBtn.classList.add("active");
    }
};

const clearLocalStorage = () => {
    delete_cookie("wcag");
    delete_cookie("wcag_theme");
    delete_cookie("wcag_font_size");
};

const moveHeader = (isWcag = false) => {
    const headerMenu = document.querySelector(".header__menu");

    if (isWcag) {
        const afterElement = document.querySelector(".header__wrapper");
        afterElement.after(headerMenu);
        return;
    }

    const afterElement = document.querySelector(
        ".header__wrapper .header__logo"
    );

    afterElement.after(headerMenu);
};

buttonColorPicker.forEach((btn) => {
    btn.addEventListener("click", (e) => {
        e.preventDefault();
        const color = e.target.dataset.color;

        if (color) {
            selectTheme(color);

            btn.classList.add("active");
        }
    });
});

buttonFontSizePicker.forEach((btn) => {
    btn.addEventListener("click", (e) => {
        e.preventDefault();
        const size = e.target.dataset.size;
        if (size) {
            selectFontSize(size);
        }
    });
});

buttonToggleWcag.forEach((btn) => {
    btn.addEventListener("click", (e) => {
        e.preventDefault();
        toggleWcag();
    });
});

document.addEventListener("DOMContentLoaded", checkLocalStorage);

function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}
function delete_cookie(name) {
    document.cookie =
        name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}
